import theme from "./theme";
import { makeStyles } from '@material-ui/core/styles';
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerContainer: {
		overflow: 'auto',
	},

	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		backgroundColor: theme.palette.background.default,
		padding: theme.spacing(3),
	},
	// https://material-ui.com/components/app-bar/
	offset: theme.mixins.toolbar,
	internalPaper: {
		padding: 10,
		margin: 10,
	},
	visualWrapPaper: {
		padding: 5,
		margin: 5,
	},
	mainView: {
		paddingLeft: drawerWidth,
		marginLeft: drawerWidth,
	},
	popover: {
		pointerEvents: 'none',
	},
	paper: {
		padding: theme.spacing(1),
	},
}));

export default useStyles;
