import React from "react";
import {
	Toolbar,
	AppBar,
	Menu,
	MenuItem,
	IconButton,
	Typography,
	Card,
	CardContent,
	CardHeader,
	TextField,
	Divider,
	Chip,
	List,
	Paper,
	ListItem,
	ListItemIcon,
	Button,
	ListItemText,
	Box,
	Popover
} from "@material-ui/core";
import theme from "./theme";
import { useSelector, useDispatch } from 'react-redux';
import Autocomplete from "@material-ui/lab/Autocomplete";
import { setDropDownChoice, setFocousImage, setInterviewRequestData} from './redux/actions.js';
import MuiPhoneInput from "material-ui-phone-number";

// for confirming interview request info
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 

import {db, firestore, auth } from './FirebaseConfig.js'
import * as firebase from 'firebase';

function isValidEmail(s){
	const res = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return res.test(String(s).toLowerCase());
}

function GetConfirmation(reqData){
	return window.confirm(`Please confirm the following Data\n\nOrganization:  \t${reqData.organization}\nContact Name:\t${reqData.contactName}\nContact Email:\t${reqData.contactEmail}\nContact Phone:\t${reqData.contactPhone}\nJob Description:\t${reqData.jobDescription}\n\nPress Okay To Submit or Cancel to make changes`);
}

export default function JumboTron () {
	const [anchorEl, setAnchorEl] = React.useState(null);
	// REQUEST INTERVIEW HOOKS
	const [organization, setOrganization] = React.useState('');
	const [contactName, setContactName] = React.useState('');
	const [contactEmail, setContactEmail] = React.useState('');
	const [contactPhone, setContactPhone] = React.useState('');
	const [jobDescription, setJobDescription] = React.useState('');
	const [autoclear, setAutoclear] = React.useState(7);
	const applicationState = useSelector(state => state);
	const dispatch = useDispatch();

	const isFirefox = typeof InstallTrigger !== 'undefined';

	React.useEffect(() => {
		//setContactName(auth.currentUser.displayName);
		//setContactEmail(auth.currentUser.email);
		//setContactPhone(auth.currentUser.phoneNumber);

		setContactName(auth.currentUser.displayName? auth.currentUser.displayName:'');
		setContactEmail(auth.currentUser.email? auth.currentUser.email:'');
		setContactPhone(auth.currentUser.phoneNumber? auth.currentUser.phoneNumber:'');

	}, []);

	/*
	 *
	 * */
	function SubmitInterviewRequest(reqData){

		let userId = auth.currentUser.uid;
		console.log(auth.currentUser);
		let postsRef = db.ref('requests/' ).child("interviewRequests");
		let newPostRef = postsRef.push();

		//	console.log(`About to add: \n\t${id}\n\t${title}`);
		console.log(new Date());
		newPostRef.set({
			organization: organization,
			contactName: contactName,
			contactEmail: contactEmail,
			contactPhone: contactPhone,
			jobDescription: jobDescription,
			momentOfRequest: `${new Date()}`,

		}, function(err) {
			if (err)
				alert("Data could not be saved." + err);
			else{
				// clear fields 
				setOrganization('');
				setContactName('');
				setContactEmail('');
				setContactPhone('');
				setJobDescription('');
			//	alert('Success. I will be in contact within the next few days.');
			}
		});
	}

	function OptionSelected (e, choice) {
		dispatch(setDropDownChoice(	(choice)? choice:"" 	));
	}

	function ShowListProjects( expIndex){
		// assuming applicationState.dropDownChoice is 'projects'
		let partialProjects = Object.values(applicationState.dropDownChoice)[expIndex];
		let fullProjects = applicationState.skills['projects'].map( (proj, index) => {
			if (partialProjects.includes(proj.name))
				return proj;
		});
		// clean with .filter()
		fullProjects = fullProjects.filter(item => typeof (item) != 'undefined' );

		return (
			<div style={{paddingLeft: 20, paddingRight: 20}}>	
				{
					fullProjects.map( (fullproject, index) => {

						return ( 
							<div key={index} style={{  backgroundColor: theme.palette.secondary.main}}>
								<Typography variant="h6" > 
									{
										`${fullproject.name}`
									}
								</Typography>
								<Typography>
									{
										`${fullproject.description}`
									}
								</Typography>
								<a target="_blank" href={`${fullproject.url}` } >
									<Chip
										size="medium"
										label="View Project Git Repo"
										clickable
										style={{ width: "100%", padding: 10, color: theme.palette.common.black  }}
									/>
								</a>
							</div>
						)
					})
				}
			</div>
		)
	}

	function ContentOrContentInList	(/*applicationState.dropDownChoice,*/ expIndex) {
		if (Array.isArray(Object.values(applicationState.dropDownChoice)[expIndex]))
			return (
				<List>
					{
						Object.values(applicationState.dropDownChoice)[expIndex].map((item, index) => {
							return (
								<ListItem>
									<ListItemText>
										{`${item}`}
									</ListItemText>
								</ListItem>
							);

						})
					}
				</List>
			)
		else 
			return (
				<Typography>{`${Object.values(applicationState.dropDownChoice)[expIndex]}` } </Typography>
			)
	}

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popover' : undefined;

	/*	Logic based entierly on redux state
	 *	UserWantsToRequestInterview()
	 *	William Doyle
	 *	March 11th 2021
	 * */
	function UserWantsToRequestInterview () {
		if (Object.keys(applicationState.skills)[applicationState.skillIndex] === 'Request Interview')
			return true;
		return false;
	}

	return (
		<Card style={{ marginTop: "2%" , marginRight: "2%"}}>
			<CardHeader
				title={(applicationState.userHasSelectedSkillSet) ? `${`${(UserWantsToRequestInterview())? '':'Search For'} ${Object.keys(applicationState.skills)[applicationState.skillIndex]}`}`:`Select a skill set from the left side menu`}
				style={{ backgroundColor: theme.palette.primary.main, textAlign: "center", color: theme.palette.common.black  }}
			/>
			<CardContent style={{ backgroundColor: theme.palette.secondary.dark}}>
				{/*		A U T O C O M P L E T E 	*/}
				{UserWantsToRequestInterview() && /*<Typography> Comming Soon</Typography> */(
					<div>
						<TextField 
							label={`Organization`}
							variant="outlined"
							value={organization}
							fullWidth
							style={{marginTop: 8}}
							onChange={evnt => setOrganization(evnt.target.value)}
						/>
						<TextField 
							defaultValue={`${auth.currentUser.displayName}`}
							label={`Contact Name`}
							value={contactName}
							variant="outlined"
							fullWidth
							style={{marginTop: 8}}
							onChange={evnt => setContactName(evnt.target.value)}
						/>
						<TextField 
							defaultValue={`${auth.currentUser.email}`}
							label={`Contact Email`}
							value={contactEmail}
							variant="outlined"
							fullWidth
							style={{marginTop: 8}}

							onChange={evnt => setContactEmail(evnt.target.value)}
						/>
						<MuiPhoneInput
							defaultValue={`${auth.currentUser.phoneNumber}`}
							label={`Contact Phone`}
							variant="outlined"
							fullWidth
							style={{marginTop: 8}}
							onChange={
								evnt => {
									setContactPhone(evnt);
								}}
						/>
						<TextField 
							label={`Job Description`}
							value={jobDescription}
							variant="outlined"
							fullWidth
							style={{marginTop: 8}}
							onChange={evnt => setJobDescription(evnt.target.value)}
						/>
						<Button onClick={async () => {
							// 1. Check fields have valid values
							// 1. a) Organization is not empty
							if (organization === ''){
								alert("Organization field may not be empty");
								return;
							}
							if (contactName === ''){
								alert("Contact Name field may not be empty");
								return;
							}
							if (!isValidEmail(contactEmail)) {
								alert("Please enter a valid email");
								return;
							}
							let submitableObject = JSON.parse(JSON.stringify( {
								organization: organization,
								contactName: contactName,
								contactEmail: contactEmail,
								contactPhone: contactPhone,
								jobDescription: jobDescription
							}));
							if (GetConfirmation(submitableObject))
								SubmitInterviewRequest(submitableObject);
						}}>
							<Typography>
								Submit Request
							</Typography>
						</Button>
					</div>

				)}
				{

					((UserWantsToRequestInterview() === false) && applicationState.userHasSelectedSkillSet) && (

						<div>
							<Autocomplete 
								key={applicationState.selectedSkill} 
								options={applicationState.selectedSkill}
								getOptionLabel={option => option.name}
								style={{ width: 300, padding: 10, color: theme.palette.common.black  }}
								onChange={OptionSelected }
								renderInput={ 
									params => (
										<TextField
											{...params}
											label={`Select ${Object.keys(applicationState.skills)[applicationState.skillIndex]}`}
											variant="outlined"
											fullWidth
										/>
									)}
							/>
							{
								<div >
									{/*<div style={{display: 'flex'}}>*/}
									{
										Object.keys(applicationState.dropDownChoice).map((expName, expIndex) => {
											if (expName == 'images') {
												return (

													<Paper key={expIndex} className={applicationState.classes.internalPaper} style={{margin: 5, padding: 20, backgroundColor: theme.palette.secondary.main, color: theme.palette.common.black }} >
														<Popover
															id="mouse-over-popover"
															className={applicationState.classes.popover}
															classes={{
																paper: applicationState.classes.paper,
															}}
															open={open}
															anchorEl={anchorEl}
															anchorOrigin={{
																vertical: 'bottom',
																horizontal: 'right',
															}}
															transformOrigin={{
																vertical: 'top',
																horizontal: 'left',
															}}
															onClose={event => {
																setAnchorEl(null);
																dispatch(setFocousImage(''));
															}}
														>
															<img src={`${applicationState.focousImage}`} alt={`${applicationState.focousImage}` } style={{width: 500, imageRendering: `${((isFirefox)? 'crisp-edges':'pixelated')}` }} />
														</Popover>

														<Typography variant="h6" >{`${expName}`} </Typography>
														<div style={{display: 'flex'}}>
															{
																Object.values(applicationState.dropDownChoice)[expIndex].map((image, imgIndex) => (
																	<div key= {imgIndex} >
																		<Button onClick={(event)  => {
																			dispatch(setFocousImage(image));
																			setAnchorEl(event.currentTarget);
																			// open module with large view of applicationState.focousImage
																		}}>
																			<img src={`${image}`} alt={`${image}` } style={{width: 'auto' }} />
																		</Button>
																	</div>
																))
															}
														</div>
													</Paper>
												)
											}
											else if (expName == 'projects'){
												return ( 
													<Paper key={expIndex} className={applicationState.classes.internalPaper} style={{ margin: 5, padding: 20, backgroundColor: theme.palette.secondary.main, color: theme.palette.common.black }}>
														<Typography variant="h6" >
															{`${expName}`} 
														</Typography>
														{ShowListProjects(expIndex)} 
													</Paper>
												)
											}
											else if (expName == 'url')  {
												return (
													<Paper key={expIndex} className={applicationState.classes.internalPaper} style={{margin: 5, padding: 20, backgroundColor: theme.palette.secondary.main, color: theme.palette.common.black }}>
														<Typography variant="h6" >{`${expName}`} </Typography>
														<a target="_blank"  href={`${Object.values(applicationState.dropDownChoice)[expIndex]}` } >
															<Typography>
																{Object.values(applicationState.dropDownChoice)[expIndex]}
															</Typography>
														</a>
													</Paper>
												)
											}
											else {
												return (
													<Paper key={expIndex} className={applicationState.classes.internalPaper} style={{margin: 5, padding: 20, backgroundColor: theme.palette.secondary.main, color: theme.palette.common.black }}>
														<Typography variant="h6" >
															{`${expName}`} 
														</Typography>
														<div>
															{ContentOrContentInList( expIndex)}
														</div>
													</Paper>
												)
											}
										})
									}
								</div>
							}
						</div>
					)
				}
			</CardContent>
		</Card>
	)
}



