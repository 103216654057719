import useStyles from "../useStyles.js";

const initialState = {
	skills: '',
	dropDownChoice: '',
	selectedSkill: {name: `select a skill -- redux says hello`},
	userHasSelectedSkillSet: false,
	skillIndex: 0,
	classes: useStyles, 
	drawerWidth: 240,
	dataStatus: 'Loading',
	focousImage: '',

	requestData: null, // data provided by company rep looking to interview me 
	ishuman: false,

	ismobile: false,
}

export function resumeStateReducer (state = initialState, action) {
	switch (action.type) {
		case 'INCREMENT':
			return Object.assign({}, state, {
				number: state.number +1
			})
		case 'DECREMENT':
			return Object.assign({}, state, {
				number: state.number -1
			})
		case 'ADDWORD':
			return{
				...state,
				words: [...state.words, action.wordToAdd]
			}
		case 'SETCURRENTWORD':
			return{
				...state,
				currentWord: action.wordToSet
			}
		case 'SETSKILLS':
			return{
				...state,
				skills: action.skills
			}
		case 'SETDROPDOWNCHOICE':
			return{
				...state,
				dropDownChoice: action.dropDownChoice
//				dropDownChoice: action.dropDownChoice.sort( /*(a,b) => {				} */)
			}
		case 'SETSELECTEDSKILL':
			return{
				...state,
				selectedSkill: action.selectedSkill
			}
		case 'SETSELECTEDSKILLSET':
			return{
				...state,
				userHasSelectedSkillSet: action.selectedSkillSet
			}
		case 'SETSKILLINDEX':
			return{
				...state,
				skillIndex: action.skillIndex
			}
		case 'SETSTATUS':
			return {
				...state,
				dataStatus: action.status
			}
		case 'SETFOCOUSIMAGE':
			return {
				...state,
				focousImage: action.focousImage
			}

		case 'SETRQDATA':
			return {
				...state,
				requestData: action.rqData
			}
		case 'SETISHUMAN':
			return {
				...state,
				ishuman: action.ishuman
			}
	case 'SETISMOBILE':
			return {
				...state,
				ismobile: action.ismobile
			}

		default:
			return state;
	}
}
