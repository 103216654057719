
// icons
import Brightness1Icon from '@material-ui/icons/Brightness1';
import DoneIcon from '@material-ui/icons/Done';
import DescriptionIcon from '@material-ui/icons/Description';
import BuildIcon from '@material-ui/icons/Build';
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import FilterFramesIcon from '@material-ui/icons/FilterFrames';


/*	iconFromSkill()
 *	icon for drawer is based on the skillset name
 *	William Doyle
 *	March 5th 2021
 * */
export default function iconFromSkill (passedskillset) {
	if (passedskillset === 'Tools')
		return (<BuildIcon/>);
	else if (passedskillset === 'Languages')
		return (<ChatBubbleOutlineIcon/>);
	else if (passedskillset === 'projects')
		return (<AccountTreeIcon />);
	else if (passedskillset === 'Frameworks')
		return (<FilterFramesIcon/>);
	else 
		return(	<Brightness1Icon/>);
}


