import {useSelector, useDispatch } from 'react-redux';
import GeneratePdfResume from "./GeneratePdfResume.js";
import Drawer from '@material-ui/core/Drawer';
import {
	Toolbar,
	AppBar,
	Typography,
	List,
	Paper,
	ListItem,
	ListItemIcon,
	Button,
	ListItemText,
	Box,
} from "@material-ui/core";
import iconFromSkill from "./iconFromSkill.js";
import TitleBar from "./TitleBar.js";
import DescriptionIcon from '@material-ui/icons/Description';
import { setSkills, setSelectedSkill, setUserHasSelectedSkillSet, setDropDownChoice, setSkillIndex} from './redux/actions.js';

import theme from "./theme";

/*
 *
 *
 * */
export default function SkillTypesDrawer () { 
	const applicationState = useSelector(state => state);
	const dispatch = useDispatch();

	return (
		<div className={applicationState.classes.root} >
			<Toolbar/>
			<Drawer
				className={applicationState.classes.drawer}
				variant="permanent"
				classes={{
					paper: applicationState.classes.drawerPaper,
				}}
				anchor="left"
			>
				<Toolbar/>
				<div className={applicationState.classes.toolbar } />
				<List style={{flex: 1,  color: theme.palette.common.black,   backgroundColor: theme.palette.secondary.main }}>
					{
						(applicationState.skills) && (
							Object.keys(applicationState.skills).map((skillset, index) => (
								<ListItem button onClick={() => {
									console.log(`${JSON.stringify(skillset)}`);
									dispatch(setDropDownChoice(''));
									dispatch(setSelectedSkill(applicationState.skills[`${skillset}`]));
									dispatch(setSkillIndex(index));
									dispatch(setUserHasSelectedSkillSet(true));
								}}  key={index} >
									<ListItemIcon style={{ color: theme.palette.common.black }}>
										{
											iconFromSkill(skillset)
										}
									</ListItemIcon>
									<ListItemText primary={skillset} />
								</ListItem>
							))
						)
					}
					<ListItem button onClick={() => {
						//	GeneratePdfResume(applicationState.skills);
						window.open(`http://williamdoyle.ca/WilliamDoyleResume.pdf`, `_blank`);
					}}>
						<ListItemIcon style={{ color: theme.palette.common.black }}><DescriptionIcon/></ListItemIcon>
						<ListItemText >
							PDF Resume
						</ListItemText>
					</ListItem>

				</List>
			</Drawer>
		</div>
	);
}

