//import * as firebase from 'firebase';
//import '@firebase/firestore';

import firebase from "firebase";
import "firebase/auth";
import "firebase/firestore";
// need to run: npm install --save firebase
// We will use the JS SDK with React Native

// for deployment
var firebaseConfig = {
	apiKey: process.env.REACT_APP_API_KEY,
	authDomain: process.env.REACT_APP_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_DATABASE_URL,
	projectId: process.env.REACT_APP_PROJECT_ID,
	storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID
};

let app = firebase.initializeApp(firebaseConfig);


//app.database().useEmulator("localhost", 9000); // remove this line to stop useing local firebase emulator

export const db = app.database();
export const firestore = firebase.firestore(app);
export const auth = app.auth();


//export const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider()
export const signInWithGoogle = () => {
	return auth.signInWithPopup(googleProvider).then((res) => {
		console.log(res.user)
	}).catch((error) => {
		console.log(`Error signing in ${error.message}`)
	})
}

