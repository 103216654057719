import React, { useState } from "react";
import { Link} from "react-router-dom";
import Reorder from "@material-ui/icons/Reorder";
import {
	Toolbar,
	AppBar,
	Menu,
	MenuItem,
	IconButton,
	Typography,
} from "@material-ui/core";
import theme from "./theme";
import useStyles from "./useStyles.js";
// for redux
import {useSelector, useDispatch } from 'react-redux';
import { setSkills, setSelectedSkill, setUserHasSelectedSkillSet, setDropDownChoice, setSkillIndex} from './redux/actions.js';
import {
	BrowserView,
	MobileView,
	isBrowser,
	isMobile
} from "react-device-detect";

export default function TitleBar () {
	const [anchorEl, setAnchorEl] = useState(null);		// Get this redux managed please... but first firgure out what it does..

	const applicationState = useSelector(state => state);
	const dispatch = useDispatch();


	function closeMenu () {
		setAnchorEl(null);
	}

	const classes = useStyles();						// this should be redux

	return (

		<AppBar position="fixed" className={classes.appBar} style={{color: theme.palette.common.black, backgroundColor: theme.palette.primary.main}}>
			<Toolbar>





				<Typography variant="h3" >
					{`R\u00E9sum\u00E9 of William Doyle`}
				</Typography>




				<MobileView>
				
					{//	M O B I L E   O N L Y 
					}

					<IconButton
						onClick={ event => setAnchorEl(event.currentTarget) }
						style={{ marginLeft: "auto", paddingRight: "1vh",  color: theme.palette.common.black  }}
					>
						<Reorder />
					</IconButton>
					<Menu
						id="simple-menu"
						anchorEl={anchorEl}
						open={Boolean(anchorEl)}
						onClose={closeMenu}
					>



	{
						(applicationState.skills) && (
							Object.keys(applicationState.skills).map((skillset, index) => (
								<MenuItem button onClick={() => {
									console.log(`${JSON.stringify(skillset)}`);
									dispatch(setDropDownChoice(''));
									dispatch(setSelectedSkill(applicationState.skills[`${skillset}`]));
									dispatch(setSkillIndex(index));
									dispatch(setUserHasSelectedSkillSet(true));
								}}  key={index} >
									<Typography>
										{skillset}
									</Typography>
								</MenuItem>
							))
						)
					}

					

					</Menu>





				</MobileView>


			</Toolbar>
		</AppBar>



	)








} 
