import React, { useReducer, useEffect } from "react";
import { MuiThemeProvider } from "@material-ui/core/styles";
import {
	Card,
	CardHeader,
	CardContent,
	Snackbar,
	Typography,
} from "@material-ui/core";
import theme from "./theme";
import "./App.css";
const requestinterview= () => {
	return (
		<MuiThemeProvider theme={theme}>
			<Card style={{ marginTop: "10%" }}>
				<CardHeader
					title="Request An Interview"
					style={{ color: theme.palette.primary.main, textAlign: "center" }}
				/>
				<CardContent>
					<Typography variant="h4" color="inherit">
						William Doyle
					</Typography>

				</CardContent>
			</Card>
				</MuiThemeProvider>
	);
};
export default requestinterview;
