import React from "react";
import './App.css';
import { Route, Link, Redirect } from "react-router-dom";
import {
	Box,
	Button,
	Typography,
} from "@material-ui/core";
import requestinterview from "./requestinterview.js";
import theme from "./theme";

// My components
import TitleBar from "./TitleBar.js";
import SkillTypesDrawer from './SkillTypesDrawer.js';
import JumboTron from "./JumboTron.js";
import MicroTron from "./MicroTron.js";

// REDUX
import { Provider } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import store from './redux/store';

// DB access
import LoadAllFromDb from './LoadAllFromDb.js';

import { setInterviewRequestData , setIsHuman, setSkills} from './redux/actions.js';

import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { signInWithGoogle, auth } from './FirebaseConfig.js'

import {
	BrowserView,
	MobileView,
	isBrowser,
	isMobile
} from "react-device-detect";

function HumanJSX () {

	const applicationState = useSelector(state => state);
	const dispatch = useDispatch();
	React.useEffect(() => {
		async function load() {
			let data = await LoadAllFromDb();	// Load data once user has proven to be a human
			dispatch(setSkills(data));
		}
		load();
	}, []);
	return (
		<div className={applicationState.classes.root}>
			<TitleBar/>

			<BrowserView>
				<SkillTypesDrawer/>
				<div>
					<Route exact path="/" render={() => <Redirect to="/" />} />
					<Route path="/requestinterview" component={requestinterview} />
				</div>

				<Box style={{marginLeft: applicationState.drawerWidth+16}}>
					<div className={applicationState.classes.toolbar}  />{/* push box content below the app bar  */}
					<JumboTron/>
				</Box>
			</BrowserView>
			<MobileView>
				<MicroTron  />
			</MobileView>
		</div>
	);
}

function SignInPage() {

	const dispatch = useDispatch();
	const applicationState = useSelector(state => state);

	return (
		<div className="login-buttons">
			<button className="login-provider-button" onClick={ async () => {
				await signInWithGoogle(); // lets not jump the gun... give the user a chance to login
				if (auth.currentUser != null) {
					dispatch(setIsHuman(true));
				}
				else {
					alert(`Sign in failed... not sure why`);
				}
			}
				}>
				<img src="https://img.icons8.com/ios-filled/50/000000/google-logo.png" alt="google icon"/>
				<span> Continue with Google</span>
			</button>

			<Button onClick={() => {
				dispatch(setIsHuman(true));
			}}>
				<Typography>
					Attempt security bypass
				</Typography>
			</Button>

		</div>
	);
}

function AppFirstChild () {

	const applicationState = useSelector(state => state);
	const dispatch = useDispatch();

	//	React.useEffect(()=>{
	//
	//	dispatch(setIsHuman(true));
	//	},[]);

	return (
		<div>
			{
				// if has pasted humanity check allow access to site and data
				// otherwise present humanity check
				//	applicationState.ishuman? <HumanJSX/>:<FullPageRecaptcha/>
				applicationState.ishuman? <HumanJSX/>:<SignInPage/>
			}
		</div>
	);
}

/*	App()
 *	Entry point to this application
 *	William Doyle
 *	March 10th 2021
 * */
function App() {
	React.useEffect(() => {
	}, []);
	return (
		<div>
		<BrowserView>
		<div style={{height: "100vh", backgroundColor: theme.palette.secondary.light }}>
			<Provider store={store}>
				<AppFirstChild/>
			</Provider>
		</div>
		</BrowserView>
		<MobileView>
		<div >
			<Provider store={store}>
				<AppFirstChild/>
			</Provider>
		</div>
	
		</MobileView>
		</div>
	);
}

export default App;


