import { db, firestore, auth } from './FirebaseConfig.js';
import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
import "firebase/firestore";
// redux
import {useDispatch } from 'react-redux';
import { setSkills, setDataStatus } from './redux/actions.js';

/*	Load Data from realtime db
 *	William Doyle
 *	March 10th 2021
 * */
async function LoadAllFromDb_realtimeDbFirebase () {
//	const dispatch = useDispatch();
	let datafound = [];	
return 	firebase.database().ref('/skillcollection/skillcollection').once('value').then( function(snapshot){
		datafound = snapshot.val();
		return datafound;
		//dispatch(setSkills(datafound)); // put all skill data into redux
	//	dispatch(setDataStatus('Loaded')); // something about this make everything slow
	});
}

/*	Load Data from DB using concrete function
 *	William Doyle
 *	March 10th 2021
 * */
export default async function LoadAllFromDb() {
return 	LoadAllFromDb_realtimeDbFirebase();
}


