
export function increment(){
	return {
		type: 'INCREMENT',
	}
}

export function decrement() {
	return {
		type:'DECREMENT',
	}
}

export function addWord(wordToAdd) {
	return {
		type: 'ADDWORD',
		wordToAdd,
	}
}

export function setCurrentWord(wordToSet) {
	return {
		type: 'SETCURRENTWORD',
		wordToSet,
	}
}

export function setSkills (skills){
	return {
		type: 'SETSKILLS',
		skills,
	}
}

export function setDropDownChoice (dropDownChoice){
	return {
		type: 'SETDROPDOWNCHOICE',
		dropDownChoice,
	}
}

export function setSelectedSkill (selectedSkill){
	return {
		type: 'SETSELECTEDSKILL',
		selectedSkill,
	}
}

export function setUserHasSelectedSkillSet (selectedSkillSet){
	return {
		type: 'SETSELECTEDSKILLSET',
		selectedSkillSet,
	}
}

export function setSkillIndex(skillIndex){
	return {
		type: 'SETSKILLINDEX',
		skillIndex,
	}
}

export function setInterviewRequestData(rqData){
	return {
		type: 'SETRQDATA',
		rqData
	}
}

export function setDataStatus(status){
	return {
		type: 'SETSTATUS',
		status,
	}
}

export function setFocousImage(focousImage){
	return {
		type: 'SETFOCOUSIMAGE',
		focousImage,
	}
}

export function setIsHuman(ishuman){
	return {
		type: 'SETISHUMAN',
		ishuman	
	}
}

export function setIsMobile(ismobile){
	return {
		type: 'SETISMOBILE',
		ismobile
	}
}


