import { createMuiTheme } from '@material-ui/core/styles';

//	P R I M A R Y
const toneA			= '#000000'		// HOT PINK

//	S E C O N D A R Y 
const toneB			= '#444444'		// MINT
const toneB_light	= '#222222'		// TIFFANY BLUE
const toneB_dark 	= '#888888' 	// YELLOW

//	P R I M A R Y
//const toneA			= '#FFAEBC'		// HOT PINK

//	S E C O N D A R Y 
//const toneB			= '#B4F8C8'		// MINT
//const toneB_light	= '#A0E7E5'		// TIFFANY BLUE
//const toneB_dark 	= '#FBE7C6' 	// YELLOW


export default createMuiTheme({
	typography: {
		useNextVariants: true
	},
	"palette": {
		"common": {
			"black": "#f55",
			"white": "#fff"
		},
		"background": {
			"paper": "#fff",
			"default": "#fafafa"
		},
		"primary": {
			"main": `${toneA}`,
		},
		"secondary": {
			"light": `${toneB_light}`,
			"main": `${toneB}`,
			"dark": `${toneB_dark}`,
		},
		"error": {
			"light": "rgba(255, 175, 175, 1)",
			"main": "#b4f8c8",
			"dark": "rgba(175, 0, 0, 1)",
			"contrastText": "rgba(0, 0, 0, 1)"
		},
		"text": {
			"primary": "rgba(255, 0, 0, 1)",
			"secondary": "#FFAEBC",
			"disabled": "rgba(167, 167, 167, 1)",
			"hint": "rgba(3, 0, 255, 1)"
		}
	}

});
//old text		//"primary": "rgba(126, 211, 33, 1)",
